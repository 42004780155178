.email_table {
  margin-left: 230px;
  padding-top: 20px;
}
.styled-table-email {
  border-collapse: collapse;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  /* max-width: 800px; */
  width: 90%;
}
.styled-table-email thead tr {
  background-color: black;
  color: #fff;
  text-align: left;
}
.styled-table-email th,
.styled-table-email td {
  padding: 12px 15px;
}
.styled-table-email tbody tr {
  border-bottom: 1px solid #000;
}
.styled-table-email tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.styled-table-email tbody tr:last-of-type {
  border-bottom: 2px solid #000;
}
