.boddashboardProductsy {
  background: hsl(0, 0%, 90%);
}
.add-product-btn-div {
  padding: 10px 0px 0px 10px;
}
.add-product-dash-btn {
  border: none;
  background-color: #2cabff;
  padding: 10px 25px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.dashboardProducts,
.dashboardProducts button,
.dashboardProducts input,
.dashboardProducts select,
.dashboardProducts textarea {
  font-size: 18px;
  line-height: 1.5;
}
.update button {
  background-color: #2d7a2d;
  color: #fff;
  border: none;
  padding: 8px 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.delete button {
  background-color: #882424;
  color: #fff;
  margin-top: 5px;
  border: none;
  padding: 8px 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.dashboardProducts h1,
.dashboardProducts h2,
.dashboardProducts h3,
.dashboardProducts h4,
.dashboardProducts h5,
.dashboardProducts h6 {
  clear: both;
  font-family: "Patua One", serif;
  letter-spacing: 2px;
}

.dashboardProducts h2 {
  font-size: 1rem;
  color: #007489;
}

.dashboardProducts p {
  margin-bottom: 1.5em;
  color: #898989;
}

.dashboardProducts b,
strong {
  font-weight: bold;
}

dfn,
cite,
em,
.dashboardProducts i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
.dashboardProducts {
  margin-left: 220px;
}
code,
kbd,
tt,
var {
  font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

.masthead {
  background-color: #005b75;
}

.masthead p,
.masthead p a {
  color: white;
}

.site-title {
  color: white;
}

.dashboardProducts img {
  border: 0;
  width: 300px;
  height: 300px;
}
.dashboardProducts img:hover {
  opacity: 0.3;
}

.dashboardcard {
  background: white;
  margin-bottom: 2em;
}

.dashboardcard a {
  color: black;
  text-decoration: none;
}

.dashboardcard a:hover {
  box-shadow: 3px 3px 8px hsl(0, 0%, 80%);
}

.dashboardcard-content {
  padding: 1.4em;
  justify-content: end;
}

.dashboardcard-content h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.dashboardcard-content p {
  font-size: 80%;
}

  @media screen and (min-width: 40em) {
    .dashboardcards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .dashboardcard {
      flex: 0 1 calc(50% - 1em);
    }
  }

  @media screen and (min-width: 60em) {
    .dashboardcard {
      flex: 0 1 calc(25% - 1em);
    }
  }
