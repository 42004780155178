@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*************Fill the Doll Section Start************/
.image__container {
  position: relative;
  max-width: 85vw;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  border: none;
}
.image__container img {
  width: 100%;
  border-radius: 20px;
  border: none;
  max-height: 500px;
  filter: saturate(0.7);
  transform: scale(1, 1.3);
  transform-origin: bottom;
}
.image__container .image__content {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}
.image__container .image__content .main__title {
  font-size: 22px;
  font-family: Nexa Bold;
}
.image__container .image__content .sub__title {
  font-size: 20px;
}
.main__title,
.sub__title {
  color: #fff;
}
.image__container .image__content button {
  margin-top: 20px;
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  background-color: #fbb040;
  color: #fff;
  font-weight: 900;
  cursor: pointer;
}
@media (max-width: 767px) {
  .image__container .image__content .main__title {
    font-size: 16px;
  }
  .image__container .image__content .sub__title {
    font-size: 15px;
  }
  .image__container .image__content button {
    padding: 0.6rem 2rem;
    font-size: 12px;
  }
  .image__container {
    width: 100%;
  }
}
@media (max-width: 468px) {
  .image__container {
    max-width: 100%;
    border-radius: 0px;
  }
  .image__container img {
    border-radius: 0px;
  }
  .image__container .image__content .main__title {
    font-size: 12px;
  }
  .image__container .image__content .sub__title {
    font-size: 12px;
  }
  .image__container .image__content button {
    padding: 0.6rem 2rem;
    font-size: 12px;
  }
  .image__container .image__content {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
  }
}

/*************Fill the Doll Section End************/

/*************Best Seller Section Start************/

.owl-dots {
  display: none;
}
.owl-carousel .owl-item img {
  width: 20%;
  height: 20%;
}

/*************Best Seller Section End************/

/*************Handmade Section Start************/
.handmade {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.show-more-btn {
  background: none;
  color: #fbb040;
  font-size: 13px;
  cursor: pointer;
  border: none;
  transition: ease-in-out all 0.3s;
}
.show-more-btn:hover {
  color: #e97436;
}

.handmade_headings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}
.handmade_headings h2 {
  color: #544a45;
}
.handmade_headings p {
  padding-top: 10px;
  border-bottom: 5px solid #e97436;
  border-radius: 3px;
  color: #fbb040;
  font-size: 24px;
  font-weight: bold;
}
.handmade_paragraphs {
  width: 40%;
  text-align: justify;
  line-height: 1.8rem;
  font-size: 18px;
  font-family: Nexa Bold;
  font-weight: 300;
}
.handmade_paragraphs p:last-child {
  padding-top: 20px;
}
.click_here {
  color: #1b978b;
}
/*************Handmade Section End************/

/**************Discover More Section Start*************/
.discover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.discover_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}
.discover_heading h2 {
  color: #544a45;
  padding: 2rem 0 0.5rem 0;
  border-bottom: 5px solid #e97436;
  display: inline-block;
  font-size: 24px;
}
.discover_heading p {
  margin-top: 25px;
  font-size: 18px;
  font-family: Nexa Light;
  font-weight: 200;
}

.discover_images {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 25px 80px;
}
.discover_images_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.discover_images_data a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.discover_images_data img {
  width: 200px;
  height: 200px;
}
.discover_images_data p {
  color: #544a45;
  font-size: 22px;
  font-weight: bolder;
  padding: 20px 0px 10px 0px;
}
.feel_it {
  color: #fbb040;
}
.clothes_image {
  border-radius: 50%;
}

/**************Discover More Section End*************/

/************    Media Query   ***********/

@media screen and (max-width: 1200px) {
  .handmade_paragraphs {
    width: 45%;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .discover_images {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 25px 0px;
  }
  .discover_images_data p {
    font-size: 18px;
  }
  .handmade_paragraphs {
    width: 70%;
    font-size: 18px;
  }
}
@media screen and(max-width:600px) {
  .handmade_headings {
    width: 70%;
  }
}
@media screen and (max-width: 468px) {
  .discover_images {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 25px 0px;
  }
  .discover_heading p {
    font-size: 17px;
  }
  .handmade_paragraphs {
    width: 90%;
    font-size: 17px;
  }
}
@media screen and (max-width: 320px) {
  .discover_heading p {
    font-size: 14px;
  }
  .discover_images_data img {
    width: 150px;
    height: 150px;
  }

  .handmade_headings h2 {
    font-size: 18px;
  }
  .handmade_headings p {
    font-size: 18px;
  }
  .handmade_paragraphs {
    width: 95%;
    font-size: 13px;
  }
  .handmade_headings {
    margin-bottom: 20px;
  }
}
