.order-data{
    margin-left: 220px;
    padding-top: 10px;
    height: 100vh;
}
.order-data-heading{
    text-align: center;
}
.client-order-details{
    font-size: 18px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap:40px;
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 10px;
    }
.data{
    font-size: 18px;
    color: #000;
    font-weight: bold;
    border: 1px solid;
    padding: 10px;
    width: 80%;
}
.product_details{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
}
.product_details_images{
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-top: -30px;
    gap: 30px;
}
.product_details_images img{
    width: 200px;
    border-radius: 5px;
}
.product_title{
    text-align: center;
}