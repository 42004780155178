@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
.Doll_House {
  background-color: #fcf4e7;
  padding: 20px;
}

.Doll_House h1 {
  color: #554a45;
  font-family: "Poppins", sans-serif;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #f7642e;
  text-decoration-color: #f7642e;
  text-underline-offset: 8px;
  padding-bottom: 10px;
}
.DollHouse_image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}
.DollHouse_image iframe {
  border-radius: 2%;
}
.dollHouse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dollsHouse_AllImages {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.dollsHouse_AllImages img {
  height: 230px;
  width: 250px;
  border-radius: 10px;
}


.dollHouse h2 {
  color: #554a45;
  font-family: "Poppins", sans-serif;
  text-align: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #f7642e;
  text-decoration-color: #f7642e;
  text-underline-offset: 8px;
  padding-bottom: 10px;
  font-size: 28px;
}

@media only screen and (max-width: 600px) {
  .Doll_House h1 {
    font-size: 18px;
    text-underline-offset: 5px;
  }
  .DollHouse_image iframe {
    width: 380px;
    height: 200px;
  }
}
@media only screen and (max-width: 870px) {
  .Fill_Dolls h1 {
    font-size: 18px;
    text-underline-offset: 5px;
  }
  .DollHouse_image iframe {
    width: 380px;
    height: 200px;
  }
  .dollsHouse_AllImages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}
@media only screen and (max-width: 468px) {
  .dollsHouse_AllImages {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

@media only screen and (max-width: 380px) {
  .DollHouse_image iframe {
    width: 90%;
  }
}
