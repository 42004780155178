.video_table {
  margin-left: 230px;
  padding-top: 20px;
}
.add-video-btn {
  border: none;
  background-color: #2cabff;
  padding: 10px 25px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.styled-table {
  border-collapse: collapse;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  /* max-width: 800px; */
  width: 90%;
}
.styled-table thead tr {
  background-color: black;
  color: #fff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #000;
}
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #000;
}
.button {
  border: none;
  color: #fff;
}
.edit-btn {
  text-decoration: none;
}
.btn-edit {
  background-color: #2d7a2d;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btn-delete {
  background-color: #882424;
  color: #fff;
  margin-left: 50px;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
